.header {
  color: #000;
  min-height: 80px;
  width: 100%;
  position: fixed;
  top: 0;
  right: 0;
  box-shadow: 0 2px 1px #00000017, 0 4px 2px #00000017, 0 8px 4px #00000017, 0 16px 8px #00000017, 0 32px 16px #00000017;
}

body.has-sidebar .header {
  width: calc(100vw - 200px) !important;
  width: calc(100% - 200px) !important;
}

/*# sourceMappingURL=index.6d320f17.css.map */
